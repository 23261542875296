import BuyArea from '../../components/buy-area';
import PaymentModal from '../../components/modals/payment-modal/payment-modal';
import { useDocument } from '../../context/document-context';
import { useModal } from '../../hooks/use-modal';
import { Preview } from './components/preview';
import SuggestionsBrowser from './components/suggestions-browser';
import { WordLimitFooter } from './components/word-limit-footer';
import * as s from './editor-workspace.styles';

const EditorWorkspace = () => {
  const { Modal, openModal } = useModal();
  const { state, send } = useDocument();

  const onStartProcessing = () => {
    send('INITIATE_DOCUMENT_PROCESSING');
    send('INITIATE_PAYMENT');
    openModal();
  };

  return (
    <>
      <s.asideLeft />
      <s.editor>
        {state.context.preview && state.context.sessionId && (
          <Preview text={state.context.preview} sessionId={state.context.sessionId} />
        )}
        <WordLimitFooter
          onBtnClick={onStartProcessing}
          hasMoreText={Boolean(state.context.hasMoreText)}
        />
      </s.editor>

      <s.asideRight>
        <s.asideRightInner>
          <SuggestionsBrowser />
          <BuyArea isPaymentCaptured={state.hasTag('paymentCaptured')} />
        </s.asideRightInner>
      </s.asideRight>
      <Modal>
        <PaymentModal />
      </Modal>
    </>
  );
};

export default EditorWorkspace;
